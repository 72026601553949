html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
} /*font-size:100%*/
body {
  line-height: 1;
  font-family: "Nanum Gothic", "Raleway", "돋움", Dotum, "굴림", Gulim, Tahoma,
    Geneva, sans-serif, verdana;
  font-size: 13px;
  -webkit-text-size-adjust: none;
  font-weight: 400 !important;
} /**/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
} /* delete vertical-align */
body,
html {
  width: 100%;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
dt {
  display: block;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
hr {
  display: none;
}
input,
select,
button,
textarea {
  vertical-align: middle;
}
img {
  vertical-align: top;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 0;
  font-size: 0;
}
button {
  cursor: pointer;
}
form,
fieldset,
button {
  border: none;
}
label {
  cursor: pointer;
}
p {
  text-align: justify;
  padding: 0;
  margin: 0;
}
address,
em {
  font-style: normal;
  font-weight: normal;
}
hr {
  display: none;
  margin: 0;
  padding: 0;
}

body {
  color: #666;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body,
input,
select,
textarea,
button {
  color: #666;
  line-height: 1.8;
}
caption,
legend {
  visibility: hidden;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
}

.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.ovfhide {
  overflow: hidden;
  height: 100vh;
}

/* loader */
.loader.--7::before,
.loader.--7::after {
  width: var(--size-square);
  height: var(--size-square);
  background-color: var(--color);
}

.loader.--7::before {
  top: calc(50% - var(--size-square));
  left: calc(50% - var(--size-square));
  animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

.loader.--7::after {
  top: 50%;
  left: 50%;
  animation: loader-7 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-7 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}
